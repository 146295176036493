<template>
  <div class="container global-list-main-container">
    <Navbar :showSearch="false"></Navbar>
    <div class="absolut-width global-lists-second-section-container">
      <div class="global-list-main-container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../../Global/components/Navbar";
// @ is an alias to /src
export default {
  name: "BankList",
  components: {
    Navbar,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
